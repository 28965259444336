import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import LandingMapImage from './image-components/landing-map-image'
import InstagramIcon from '../../images/instagram'
import TwitterIcon from '../../images/twitter'
import FacebookIcon from '../../images/facebook'
import LinkedInIcon from '../../images/linked-in'
import TelegramIcon from '../../images/telegram'

const Wrapper = styled.section`
  height: 45vmax;
  display: flex;
  position: relative;
  ${props => props.theme.media.sm`
    flex-direction: column-reverse;
    height: 75vmax;
  `};
`

const ContactUsContainer = styled.div`
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 65%;
  width: 50%;
  z-index: 1;
  padding: 6vmax 6vmax;
  ${props => props.theme.media.sm`
    position: relative;
    width: 100%;
    height: 45vmax;
    padding: 5vmax 3vmax;
  `};
`

const Title = styled.h1`
  margin-bottom: 1.4rem;
  font-size: 3.5rem;
  white-space: pre-line;
  ${props => props.theme.media.sm`
    font-size: 3.3rem;
`};
`

const InfoWrapper = styled.div`
  padding-top: 2vw;
  display: flex;
  font-size: 1.1rem;
  line-height: 1.8rem;
  > div {
    flex: 1;
    &:last-child {
      flex: none;
    }
    ${props => props.theme.media.sm`
     font-size: 1rem;
   `};
  }
`

const SocialItem = styled.a`
  color: #bebebe;
  display: block;
  :not(:last-child) {
    margin-bottom: 1.4rem;
  }
  :hover {
    color: #969696;
  }
  svg {
    width: 1.4rem;
  }
  transition: opacity 300ms ease-in-out;
`

const EmailItem = styled.a`
  color: ${props => props.theme.palette.secondary};
  :hover {
    color: ${props => props.theme.gradients.secondary.hover};
  }
`

const LandingMapAndContact = () => {
  const {
    site: { siteMetadata: info },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
            address {
              street
              zip
              city
              country
            }
            phone
          }
        }
      }
    `,
  )
  return (
    <Wrapper>
      <ContactUsContainer>
        <Title>{'CONTACT\nUS'}</Title>
        <InfoWrapper>
          <div>
            <div>
              {info.address.street}, {info.address.zip},
            </div>
            <div>
              {info.address.city}, {info.address.country}
            </div>
          </div>
          <div>
            <div>{info.phone}</div>
            <EmailItem href={`mailto:${info.email}`}>{info.email}</EmailItem>
          </div>
          <div>
            <SocialItem
              href="https://t.me/techpad_co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon />
            </SocialItem>
            <SocialItem
              href="https://twitter.com/techpad_co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </SocialItem>
            <SocialItem
              href="https://www.linkedin.com/company/techpad-co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </SocialItem>
            <SocialItem
              href="https://www.facebook.com/techpadco/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </SocialItem>
            <SocialItem
              href="https://www.instagram.com/techpad_co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </SocialItem>
          </div>
        </InfoWrapper>
      </ContactUsContainer>
      <LandingMapImage />
    </Wrapper>
  )
}

export default LandingMapAndContact

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

const LandingMapImage = props => {
  const { map } = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "map.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage Tag="div" fluid={map.childImageSharp.fluid} {...props} />
  )
}

const StyledLandingMapImage = styled(LandingMapImage)`
  width: 100%;
  height: 100%;

  ${props => props.theme.media.sm`
    &&&&&&&&& {
      &::before,&::after {
        background-position: 0;
      }
    }
  `};
`

export default StyledLandingMapImage

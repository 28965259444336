import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import FullScreenOverlay from '../components/full-screen-modal/full-screen-overlay'
import useForm from '../hooks/use-form'
import Button from '../ui/button'
import FormInput from '../ui/form-input'
import ApplicationSuccess from './application-success'

const FormsContainer = styled.div`
  min-width: 500px;
  max-width: 33vw;
  margin: auto;
  text-align: center;
  form {
    margin-top: 4vmax;
  }
  ${props => props.theme.media.sm`
    width: 80%;
    min-width: unset;
    max-width: unset;
  `};
`

const Title = styled.h1`
  margin-bottom: 0.5vmax;
  margin-top: 3vmax;
  font-size: 3.2rem;
  text-transform: uppercase;
  ${props => props.theme.media.sm`
    font-size: 2.5rem;
    margin-top: 5vmax;
  `};
`

const Subtitle = styled.div`
  font-size: 0.8rem;
`

const SubmitButton = styled(Button)`
  margin-top: 2vmax;
`

const JoinTeamForm = ({ closeModal, position }) => {
  const [successBg, setSuccessBg] = React.useState('')
  const [newPosition, setPosition] = React.useState(position)
  const { handleSubmit, submitting, resetFileString } = useForm({
    onSubmit: () => {
      setSuccessBg('primary') // using primary now always, instead of position ? 'primary' : 'secondary'
    },
  })

  return (
    <FormsContainer>
      <Title>Join our team</Title>
      <Subtitle>
        Become a part of our team {newPosition && `as a "${newPosition}"`}
      </Subtitle>
      <form onSubmit={handleSubmit} name="Join-team" data-netlify="true">
        <input type="hidden" name="form-name" value="Join-team" />
        <p style={{ display: 'none' }}>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <FormInput
          name="name"
          placeholder="Your name"
          label="name"
          autoFocus
          disabled={submitting}
          required
        />
        <FormInput
          name="email"
          placeholder="Your email"
          label="email"
          type="email"
          disabled={submitting}
          required
        />
        <FormInput
          name="vacancy"
          {...(!position
            ? {
                label: 'position',
                required: true,
                placeholder: 'What is your desired position?',
                disabled: submitting,
                onBlur: e => setPosition(e.target.value),
              }
            : { hidden: true, defaultValue: position })}
        />
        <FormInput
          name="message"
          placeholder="Additional information"
          kind="textarea"
          disabled={submitting}
          label="message"
        />
        <FormInput
          key={resetFileString}
          name="cv"
          placeholder="Attach your CV"
          kind="file"
          label="CV"
          disabled={submitting}
          color="primary"
        />
        <SubmitButton
          color="primary"
          label={submitting ? 'Uploading...' : 'Send'}
          type="submit"
          disabled={submitting}
        />
      </form>
      {successBg && (
        <FullScreenOverlay color={successBg} withHeader={false}>
          <ApplicationSuccess color={successBg} onClose={closeModal} />
        </FullScreenOverlay>
      )}
    </FormsContainer>
  )
}

JoinTeamForm.propTypes = {
  closeModal: PropTypes.func,
}

export default JoinTeamForm

import * as PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import ArrowButton from '../../ui/arrow-button'

const StyledArrowButton = styled(ArrowButton)`
  transform: ${p => p.backwards && 'rotate(180deg)'};
  ${p => p.backwards && 'margin-right: 1rem;'};
  ${p => !p.backwards && 'margin-left: 1rem;'};
`

const hoverStyles = css`
  &:after {
    content: "${props => props.hoverText}";
    opacity: 0;
  }
  &:before {
    content: "${props => props.text}";
    opacity: 1;
  }
  &:after,
  &:before {
    float: right;
    position: absolute;
    ${p => `${p.backwards ? 'left' : 'right'}: 3em`};
    transition: opacity 0.3s ease-in-out;
  }
    width: 14vmax;
`

const Text = styled.div`
  text-align: right;
  white-space: nowrap;
  pointer-events: none;
  ${p => p.enableHover && hoverStyles};
`

const CtaWrapper = styled.div`
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  font-size: 1.7rem;
  background: ${props => props.theme.gradients[props.color].main};
  :before {
    content: '';
    background: ${props => props.theme.gradients[props.color].hovered};
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.4s ease-in-out;
  }
  &:hover {
    ${StyledArrowButton} {
      transform: ${p => p.backwards && 'rotate(180deg)'} translateX(0.2vw);
    }
    ${Text}:after {
      opacity: 1;
    }
    ${Text}:before {
      opacity: ${props => (props.hoverText.endsWith(props.text) ? 1 : 0)};
    }
    &:before {
      opacity: 1;
    }
  }
`

const orientationStyles = {
  horizontal: css`
    ${p => `${p.sideTextPosition.split('-')[0]}: 0`};
  `,
  vertical: css`
    transform: rotate(270deg) translateY(50%) translateX(-50%);
    transform-origin: left;
  `,
}

const SideText = styled.div`
  position: absolute;
  font-size: 0.5em;
  ${p => `${p.sideTextPosition.split('-')[1]}: 0`};
  padding: 2em;
  ${props => orientationStyles[props.sideTextOrientation]};
  pointer-events: none;
  white-space: pre-line;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${p => p.backwards && 'row-reverse'};
  align-items: center;
  position: relative;
  font-weight: bold;
  pointer-events: none;
`

const CtaBox = ({ className, ...props }) => {
  const enableHover = props.text !== props.hoverText
  return (
    <CtaWrapper {...props} className={className}>
      <ButtonWrapper {...props}>
        <Text {...props} enableHover={enableHover}>
          {enableHover ? <>&nbsp;</> : props.text}
        </Text>
        <StyledArrowButton color={props.color} backwards={props.backwards} />
      </ButtonWrapper>
      <SideText {...props}>{props.sideText}</SideText>
    </CtaWrapper>
  )
}

CtaBox.propTypes = {
  text: PropTypes.string.isRequired,
  hoverText: PropTypes.string.isRequired,
  sideTextOrientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  sideTextPosition: PropTypes.oneOf([
    'bottom-left',
    'top-left',
    'bottom-right',
    'top-right',
  ]),
  backwards: PropTypes.bool,
  sideText: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
}

CtaBox.defaultProps = {
  sideTextPosition: 'bottom-left',
}

export default CtaBox

import React from 'react'

const TwitterIcon = props => (
  <svg x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" {...props}>
    <path
      fill="currentColor"
      d="M1000 190c-36.8 16.3-76.3 27.4-117.8 32.3 42.4-25.4 74.8-65.6 90.2-113.4-39.7 23.5-83.6 40.6-130.4 49.8-37.4-39.9-90.7-64.8-149.7-64.8-113.3 0-205.1 91.9-205.1 205.1 0 16.1 1.8 31.7 5.3 46.7-170.5-8.6-321.7-90.2-422.8-214.4-17.7 30.3-27.8 65.5-27.8 103.2 0 71.2 36.2 134 91.2 170.7-33.6-1.1-65.2-10.4-92.9-25.7v2.6c0 99.4 70.7 182.3 164.5 201.2-17.2 4.6-35.3 7.2-54.1 7.2-13.2 0-26.1-1.3-38.6-3.8 26.2 81.4 102 140.7 191.7 142.3-70.2 55-158.7 87.7-254.8 87.7-16.6 0-32.9-1-48.9-2.8 90.8 58.3 198.6 92.3 314.4 92.3 377.4 0 583.6-312.6 583.6-583.6l-.7-26.6c40.4-28.8 75.3-64.9 102.7-106z"
    />
  </svg>
)

export default TwitterIcon

import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import useDimension from '../hooks/use-dimension'
import ArrowButton from '../ui/arrow-button'
import Animate from './animate'

const ArrowDownContainer = styled.div`
  position: absolute;
  margin: auto;
  top: 65%;
  font-size: 1.7rem;
`

const ArrowDown = styled(ArrowButton)`
  ${p => p.css};
  animation-name: ${p => p.theme.animations.arrowDown};
  transform: rotate(90deg);
  cursor: default;
`

const PageTitleWrapper = styled.div`
  height: 22vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: 1px solid ${p => p.theme.border.line};
  border-bottom: 1px solid ${p => p.theme.border.line};
  ${props => props.theme.media.xs`
    height: 33vmax;
  `};
`

const TitleContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${p => p.theme.border.line};
  border-bottom: 1px solid ${p => p.theme.border.line};
  position: relative;
  bottom: 10%;
`

const Title = styled.h1`
  ${p => p.css};
  animation-name: ${p => p.theme.animations.innerPageTitle};
  margin: -2px 0 3px;
  line-height: 0.7;
  padding-top: 1px;
  font-weight: bold;
  font-size: 3.9rem;
  ${props => props.theme.media.xs`
    font-size: 3.2rem;
  `};
  text-transform: uppercase;
  text-align: center;
`

const Inner = styled.div`
  position: absolute;
  width: ${p => {
    if (p.width) return p.width - 2 + 'px'
    return '16.5vw'
  }};
  height: 100%;
  border-left: 1px solid ${p => p.theme.border.line};
  border-right: 1px solid ${p => p.theme.border.line};
`

const BodyContainer = styled.div`
  position: absolute;
  left: 65%;
  top: 69%;
`

const PageTitle = ({ title, children, animate }) => {
  const [dimensions, setRef] = useDimension()
  return (
    <PageTitleWrapper>
      <Title
        ref={setRef}
        as="span"
        css={`
          visibility: hidden;
          width: unset;
          position: absolute;
        `}
      >
        {title}
      </Title>
      <TitleContainer>
        <Animate duration="200ms" animate={animate}>
          {props => <Title {...props}>{title}</Title>}
        </Animate>
      </TitleContainer>
      <Inner width={dimensions.width} />
      <ArrowDownContainer>
        <Animate delay="200ms" animate={animate}>
          {props => (
            <ArrowDown
              color="primary"
              variant="contained"
              gradient
              {...props}
            />
          )}
        </Animate>
      </ArrowDownContainer>
      <BodyContainer>{children}</BodyContainer>
    </PageTitleWrapper>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle

import React from 'react'

const FacebookIcon = props => (
  <svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
    <path
      fill="currentColor"
      d="M384 .1L317.6 0C243 0 194.8 49.5 194.8 126v58.1H128c-5.8 0-10.4 4.7-10.4 10.4v84.2c0 5.8 4.7 10.4 10.4 10.4h66.8v212.4c0 5.8 4.7 10.4 10.4 10.4h87.1c5.8 0 10.4-4.7 10.4-10.4V289.2h78.1c5.8 0 10.4-4.7 10.4-10.4v-84.2c0-2.8-1.1-5.4-3.1-7.4s-4.6-3.1-7.4-3.1h-78.1v-49.2c0-23.7 5.6-35.7 36.5-35.7h44.7c5.8 0 10.4-4.7 10.4-10.4V10.5C394.4 4.8 389.7.1 384 .1z"
    />
  </svg>
)

export default FacebookIcon

import * as PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import CircleButton from '../../ui/circle-button'

const AccordionWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.border.main};
  border-bottom: 1px solid ${props => props.theme.border.main};
  font-size: 0.9rem;
  white-space: pre-wrap;
  & + & {
    border-top: none;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 2vmax 0;
`

const Title = styled.div`
  font-size: 2.3em;
  ${props => props.theme.media.sm`
    font-size: 1.5em;
  `};
  text-transform: uppercase;
  font-weight: bold;
`

const BodyContainer = styled.div`
  line-height: 1.9rem;
  padding-bottom: 2vw;
  padding-left: 0.5vw;
  display: flex;
  flex-direction: column;
`

const Accordion = props => {
  const { title, children, isExpanded } = props
  const [expanded, setExpanded] = React.useState(isExpanded)

  return (
    <AccordionWrapper>
      <Header onClick={() => setExpanded(e => !e)}>
        <Title>{title}</Title>
        <CircleButton color="secondary" variant="contained">
          {expanded ? '-' : '+'}
        </CircleButton>
      </Header>
      {expanded && <BodyContainer>{children}</BodyContainer>}
    </AccordionWrapper>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isExpanded: PropTypes.bool,
}

export default Accordion

import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ContainerElement = styled.section`
  width: ${p => p.width};
  height: ${p => p.height};
  ${p =>
    p.width &&
    p.theme.media.sm`
    width: ${p.smFull ? 100 : 84}vw;
  `}
  ${p =>
    p.centered &&
    css`
      margin: auto;
    `};
  ${p =>
    p.mb &&
    css`
      margin-bottom: ${p.mb};
    `};
`

const Container = ({ children, ...rest }) => (
  <ContainerElement {...rest}>{children}</ContainerElement>
)

Container.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  centered: PropTypes.bool,
  smFull: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  mb: PropTypes.string,
}

Container.defaultProps = {
  centered: true,
}

export default Container

import React from 'react'

export default function useDimension() {
  const [ref, setRef] = React.useState(null)
  const [dimensions, setDimensions] = React.useState({})

  React.useEffect(() => {
    ref && setDimensions(ref.getBoundingClientRect())
  }, [ref, setDimensions])

  return [dimensions, setRef]
}

import React from 'react'
import * as PropTypes from 'prop-types'
import JoinTeamForm from '../../forms/join-team-form'
import ArrowLink from '../../ui/arrow-link'
import Filter from '../../ui/filter'
import Accordion from '../accordion/accordion'
import styled from 'styled-components'
import FullScreenModal from '../full-screen-modal/full-screen-modal'

const SectionTitle = styled.div`
  margin: 2vmax 0 1vmax 0;
  font-weight: bold;
  text-transform: uppercase;
`

const ApplyNowLink = styled(ArrowLink)`
  align-self: flex-end;
  font-size: 1rem;
`

const StyledFilter = styled(Filter)`
  margin-bottom: 4vmax;
  ${props => props.theme.media.sm`
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  `};
`

const Jobs = ({ jobs }) => {
  const [groupedJobs] = React.useState(() =>
    jobs.reduce(
      (agg, el) => {
        agg.list = [...new Set([...agg.list, ...el.categories])]
        el.categories.forEach(category => {
          if (!agg[category]) {
            agg[category] = el.openPosition ? [el] : []
            el.openPosition && agg.all.push(el)
          } else if (el.openPosition) {
            agg[category].push(el)
            agg.all.push(el)
          }
        })
        return agg
      },
      { list: ['all'], all: [] },
    ),
  )

  const [selected, setSelected] = React.useState(groupedJobs.list[0])
  const filters = groupedJobs.list.map(i => ({
    title: i,
    matches: groupedJobs[i].length,
    displayCount: i !== 'all',
  }))

  return (
    <>
      <StyledFilter
        filters={filters}
        onClick={setSelected}
        activeFilter={selected}
      />
      {groupedJobs[selected].map(({ title, skillSet, offerings, tasks }) => (
        <Accordion title={title} key={title}>
          <SectionTitle>What you will do:</SectionTitle>
          {tasks.map(task => (
            <span key={task}>{`•  ${task}`}</span>
          ))}
          <SectionTitle>What we expect from you:</SectionTitle>
          {skillSet.map(skill => (
            <div key={skill}>{`•  ${skill}`}</div>
          ))}
          <SectionTitle>What we offer:</SectionTitle>
          {offerings.map(offering => (
            <div key={offering}>{`•  ${offering}`}</div>
          ))}
          <FullScreenModal
            withHeader
            color="primary"
            trigger={
              <ApplyNowLink label="Apply Now" gradient color="primary" />
            }
          >
            <JoinTeamForm position={title} />
          </FullScreenModal>
        </Accordion>
      ))}
    </>
  )
}

Jobs.propTypes = {
  jobs: PropTypes.array,
}

export default Jobs

import React from 'react'
import styled, { css } from 'styled-components'

const activeStyles = css`
  background-color: ${p => p.theme.text.black.main};
  color: white;
`

const disabledFilterStyles = css`
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
`

const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${p => p.filterCount}, 1fr);
`

const FilterItem = styled.div`
  margin-top: -1px;
  margin-left: -1px;
  padding: 1.2rem 1.4rem;
  border: 1px solid ${p => p.theme.text.black.main};
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: ${p => (p.active ? p.theme.text.black.main : '#e6e6e6')};
  }
  ${p => p.active && activeStyles}
  ${p => p.disabled && disabledFilterStyles};
`

const Filter = ({ filters, onClick, className, activeFilter }) => {
  return (
    <FiltersWrapper className={className} filterCount={filters.length}>
      {filters.map(filter => (
        <FilterItem
          active={activeFilter === filter.title}
          disabled={!filter.matches}
          onClick={() => onClick(filter.title)}
          key={filter.title}
        >
          {filter.title}
          {filter.displayCount && ` (${filter.matches})`}
        </FilterItem>
      ))}
    </FiltersWrapper>
  )
}

export default Filter

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Jobs from '../components/careers/jobs'
import JoinTeam from '../components/careers/join-team'
import Container from '../components/container'
import FullScreenModal from '../components/full-screen-modal/full-screen-modal'
import CtaBox from '../components/landing/landing-cta-box'
import LandingMapAndContact from '../components/landing/landing-map-contact'
import PageTitle from '../components/page-title'
import SEO from '../components/seo'
import JoinTeamForm from '../forms/join-team-form'
import ProjectForm from '../forms/project-form'
import QuestionForm from '../forms/question-form'
import styled from 'styled-components'

const StyledBoxContainer = styled.div`
  height: 30vmax;
`

const CareersPage = ({ animate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
            jobs {
              categories
              skillSet
              offerings
              tasks
              title
              openPosition
            }
          }
        }
      }
    `,
  )
  return (
    <>
      <SEO
        title="Careers"
        description="We're looking for intelligent, motivated and hard-working people who are interested in building digital products that solve real-world problems. If you are thrilled about working in cutting edge technologies or you have a passion for emerging Blockchain technology, please make sure to write to us."
      />
      <Container mb="6vw">
        <PageTitle title="Careers" animate={animate} />
      </Container>
      <Container width="90vw" mb="6vw">
        <JoinTeam />
      </Container>
      <Container width="60vw" mb="6vw">
        <Jobs jobs={site.siteMetadata.jobs} />
      </Container>
      <FullScreenModal
        withHeader
        color="primary"
        trigger={
          <StyledBoxContainer>
            <CtaBox
              color="primary"
              text="APPLICATION"
              hoverText="SEND APPLICATION"
              sideText={`Did not find desired position? Write to us and attach your CV.\n We're always looking for new creative people in our company.`}
              sideTextOrientation="horizontal"
              sideTextPosition="top-left"
            />
          </StyledBoxContainer>
        }
      >
        <JoinTeamForm />
      </FullScreenModal>
      <LandingMapAndContact />
      <div style={{ display: 'none ' }}>
        <QuestionForm onSubmit={() => {}} />
        <ProjectForm onSubmit={() => {}} />
        <JoinTeamForm />
      </div>
    </>
  )
}

export default CareersPage

import React from 'react'
import styled from 'styled-components'

const JoinTeamWrapper = styled.div`
  display: flex;
  ${props => props.theme.media.sm`
    flex-direction: column;
  `};
`

const Title = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: 2.5rem;
  flex-basis: 33%;
  white-space: pre;
  line-height: 1;
  font-weight: bold;
  ${props => props.theme.media.sm`
    white-space: normal;
    text-align: center;
    display: block;
    margin-bottom: 2rem;
    margin-top: 2rem;
  `};
`

const TextWrapper = styled.div`
  display: flex;
  font-size: 1rem;
  color: ${p => p.theme.text.black[200]};
  flex: 1;
  ${props => props.theme.media.sm`
    flex-basis: 100%;
  `};
  text-align: center;
`

const Text = styled.div`
  width: 50%;
  font-size: 1.1rem;
  line-height: 1.9rem;
  ${props => props.theme.media.sm`
    width: 100%;
  `};
  .darker {
    color: ${p => p.theme.text.black[100]};
    display: inline;
  }
`

const JoinTeam = () => {
  return (
    <JoinTeamWrapper>
      <Title>{'Join our\nTeam '}</Title>
      <TextWrapper>
        <Text>
          We’re looking for <div className="darker">intelligent</div>, motivated
          and <div className="darker">hard-working people</div> who are
          interested in building
          <div className="darker"> digital products</div> that solve real-world
          problems. If you are thrilled about working in{' '}
          <div className="darker">cutting edge</div> technologies or you have a
          passion for emerging{' '}
          <div className="darker">Blockchain technology</div>, please make sure
          to write to us.
        </Text>
      </TextWrapper>
    </JoinTeamWrapper>
  )
}

export default JoinTeam

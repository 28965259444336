import React from 'react'

const TelegramIcon = props => (
  <svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
    <path
      d="M200.9 323.9L192.4 443c12.1 0 17.4-5.2 23.7-11.5l56.8-54.3 117.7 86.2c21.6 12 36.8 5.7 42.6-19.9l77.3-362.1c6.8-31.9-11.5-44.4-32.6-36.6l-454.1 174c-31 12-30.5 29.3-5.3 37.1L134.6 292l269.7-168.8c12.7-8.4 24.2-3.8 14.7 4.7l-218.1 196z"
      fill="currentColor"
    />
  </svg>
)

export default TelegramIcon
